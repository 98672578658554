import React from 'react';
import styled from 'styled-components';
import { CONSTANTS } from 'constants/styles/constants';
import { AdvantageItem } from 'modules/objective-advantages-section/components/advantage-item';
import { arrayOf, number, shape, string } from 'prop-types';
import { isMobile } from 'utilities';

const SColumnsContainer = styled.div`
    display: flex;
    gap: 4rem;
    margin-left: 1.5rem;

    ${CONSTANTS.MEDIA.max1024`
        flex-direction: column;
    `};

    ${CONSTANTS.MEDIA.max600`
        margin-left: 0;
    `};
`;

const SColumnContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 3.75rem;
`;

const getAdvantages = (data, rootPath) => {
    const odd = [];
    const even = [];
    const all = [];

    for (const item of data) {
        const reactItem = (
            <AdvantageItem {...item} key={item.index} rootPath={rootPath} />
        );
        all.push(reactItem);

        if (item.index % 2 === 0) {
            even.push(reactItem);
        } else {
            odd.push(reactItem);
        }
    }

    return { all, odd, even };
};

export const Advantages = ({ data, rootPath }) => {
    const { all, odd, even } = getAdvantages(data, rootPath);

    return (
        <SColumnsContainer>
            {isMobile() ? (
                <SColumnContainer>{all}</SColumnContainer>
            ) : (
                <>
                    <SColumnContainer>{odd}</SColumnContainer>
                    <SColumnContainer>{even}</SColumnContainer>
                </>
            )}
        </SColumnsContainer>
    );
};

Advantages.propTypes = {
    data: arrayOf(
        shape({
            index: number,
            section: string,
            paragraphsCount: number,
        }),
    ).isRequired,
    rootPath: string.isRequired,
};
