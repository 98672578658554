import React from 'react';
import { PropTechRealEstateView } from 'views/prop-tech';
import { SEO } from 'components/seo';
import { TAGS } from 'constants/tags';
import aiAssistantMessages from 'constants/translations/ai-assistant.json';
import caseStudiesMessages from 'constants/translations/case-studies/index.json';
import messages from 'constants/translations/expertises/prop-tech-real-estate.json';
import { graphql } from 'gatsby';
import Layout from 'layouts/standard';
import { object } from 'prop-types';
import { formatFaqData } from 'utilities/contentful';

const PropTech = ({ location, data }) => {
    const faqData = formatFaqData(data?.allContentfulFaq.nodes[0]);

    return (
        <Layout
            location={location}
            messages={{
                ...messages,
                ...caseStudiesMessages,
                ...aiAssistantMessages,
            }}
        >
            <PropTechRealEstateView {...{ faqData }} />
        </Layout>
    );
};

PropTech.propTypes = {
    location: object.isRequired,
};

export default PropTech;

export const Head = () => <SEO tags={TAGS.PROP_TECH_REAL_ESTATE} />;

export const pageQuery = graphql`
    query ProptechPageQuery {
        allContentfulFaq(
            filter: { slug: { eq: "expertises/prop-tech-real-estate" } }
        ) {
            nodes {
                ...FaqFragment
            }
        }
    }
`;
