import React from 'react';
import { PropTechBadgesSection } from 'views/prop-tech/prop-tech-badges-section';
import { PropTechStudiesSection } from 'views/prop-tech/prop-tech-case-studies';
import { PropTechMainSection } from 'views/prop-tech/prop-tech-main-section';
import { PropTechSection } from 'views/prop-tech/prop-tech-section';
import { RealEstateDevelopersSection } from 'views/prop-tech/real-estate-developers-section';
import { RealEstateSoftwareSection } from 'views/prop-tech/real-estate-software-section';
import { AIAssistantSection } from 'modules/ai-assistant-section';
import { FooterSection } from 'modules/footer-section';
import { ObjectiveAdvantagesSection } from 'modules/objective-advantages-section';
import { array } from 'prop-types';

import {
    ADVANTAGES,
    ADVANTAGES_FOOTER_LINKS,
    LATEST_RELATED_ARTICLES_SETTINGS,
} from './constants';
import { PropTechCtaSectionExtended } from './prop-tech-cta-section-extended';
const Props = {
    faqData: array.isRequired,
};

export const PropTechRealEstateView = ({ faqData }) => {
    return (
        <>
            <PropTechMainSection />
            <PropTechStudiesSection />
            <RealEstateSoftwareSection />
            <AIAssistantSection />
            <PropTechSection />
            <PropTechBadgesSection />
            <ObjectiveAdvantagesSection
                title="view-prop-tech-real-estate.objectiveAdvantages.title"
                subTitle="view-prop-tech-real-estate.objectiveAdvantages.subTitle"
                rootPath="view-prop-tech-real-estate.objectiveAdvantages"
                footer="view-prop-tech-real-estate.objectiveAdvantages.footer"
                footerLinks={ADVANTAGES_FOOTER_LINKS}
                data={ADVANTAGES}
            />
            <PropTechCtaSectionExtended />
            <RealEstateDevelopersSection />
            <FooterSection
                faqSection={{
                    title: faqData.title,
                    data: faqData.data,
                    parseRawAnswer: true,
                }}
                latestRelatedArticles={LATEST_RELATED_ARTICLES_SETTINGS}
            />
        </>
    );
};

PropTechRealEstateView.propTypes = Props;
